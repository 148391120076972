<template>
  <div>
    <t-card>
      <template v-slot:header>
        <div class="w-full px-5 py-5">
          <div class="flex items-center justify-between px-5">
            <h1 class="font-bold text-xl">PARTIDOS DEL DÍA</h1>
            <t-button
              variant="warning"
              @click="$router.push('/torneos')"
              v-if="seeAll"
              ><span class="font-bold text-sm text-white"
                >VER TODOS</span
              ></t-button
            >
          </div>
        </div>

        <div class="w-full px-3 py-5 bg-gray-100">
          <p class="text-gray-500 text-lg text-center capitalize">
            {{
              new Intl.DateTimeFormat("es-ES", {
                dateStyle: "full",
                timeZone: "America/Caracas"
              }).format(new Date())
            }}
          </p>
        </div>
      </template>
      <div class="mx-auto p-3" v-if="data.length === 0">
        <p class="text-md text-center">No hay juegos para hoy</p>
      </div>

      <div v-for="game in data" :key="game.id">
        <div
          class="xl:px-5 grid grid-cols-2 xs:grid-cols-5 gap-2 my-2 py-3 border-b"
        >
          <div class="h-12 w-12 mx-auto my-auto">
            <img
              v-if="game.home_team && game.home_team.url_logo"
              class="object-scale-down w-full h-full"
              style="aspect-ratio: 1"
              :src="game.home_team.url_logo"
              alt="Home team logo"
            />
          </div>
          <div class="my-auto">
            <p class="text-sm font-bold">
              {{ game.home_team.name }}
            </p>
            <p class="text-xs">
              {{ game.category.name }}
            </p>
          </div>
          <div class="flex flex-col justify-between">
            <p class="text-xs mx-auto text-center">
              {{ game.tournament.name }}
            </p>
            <div class="my-auto mx-auto">
              <p class="text-2xl font-bold text-center">
                {{ game.home_goals }} - {{ game.visit_goals }}
              </p>
              <p
                class="text-md font-bold text-center"
                v-if="game.home_penalty_goals || game.visit_penalty_goals"
              >
                ({{ game.home_penalty_goals }} - {{ game.visit_penalty_goals }})
              </p>
              <p class="text-xs text-center">
                {{ parseInt(game.hour.substring(0,2)) > 12 ? (parseInt(game.hour.substring(0,2)) - 12) + ':' + game.hour.substring(3,5) + ' PM' : parseInt(game.hour.substring(0,2)) + ':' + game.hour.substring(3,5) + ' AM' }}
              </p>
            </div>
          </div>
          <div class="h-12 w-12 mx-auto my-auto">
            <img
              v-if="game.visit_team && game.visit_team.url_logo"
              class="object-scale-down w-full h-full"
              style="aspect-ratio: 1"
              :src="game.visit_team.url_logo"
              alt="Visit team logo"
            />
          </div>
          <div class="my-auto">
            <p class="text-sm font-bold">
              {{ game.visit_team.name }}
            </p>
            <p class="text-xs">
              {{ game.category.name }}
            </p>
          </div>
        </div>
      </div>
    </t-card>
  </div>
</template>
<script>
export default {
  name: "scoreboard",
  props: {
    route: {
      type: String
    },
    module: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      default: () => []
    },
    orderKey: {
      type: String,
      default: ""
    },
    orderValue: {
      type: String,
      default: ""
    },
    action: {
      type: String,
      default: "global/getItems"
    },
    seeAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingData: false
    }
  },
  computed: {
    filteredData() {
      return this.data
    }
  },
  methods: {
    async getItems() {
      let form = {
        route: `${this.route}`,
        module: this.module,
        params: { page: this.current || 1 }
      }

      if (this.orderKey != "" && this.orderValue != "") {
        form.params.orderKey = this.orderKey
        form.params.orderValue = this.orderValue
      }

      await this.$store
        .dispatch(this.action, form)
        .then((result) => {
          if (result.data.meta) {
            this.paginate = result.data.meta
          }
          this.loadingData = false
        })
        .catch(() => {
          this.loadingData = false
        })
    }
  },
  created() {
    this.getItems()
  }
}
</script>
