<template>
  <div>
    <div class="mx-auto text-center" v-if="loading">
      <t-loader extraClass="border-primary-900 w-8 h-8" />
    </div>

    <div v-if="!loading" class="pt-2">
      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Torneos',
              quantity: data.counters.tournaments.total,
              icon: 'trophy',
              change: data.counters.tournaments.actives,
              changeVar: 'torneos en curso'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Academias',
              quantity: data.counters.academies.total,
              icon: 'shield-alt',
              change: data.counters.academies.actives,
              changeVar: 'equipos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>

        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Jugadores',
              quantity: data.counters.players.total,
              icon: 'users',
              change: data.counters.players.actives,
              changeVar: 'jugadores activos'
            }"
            bgColor="bg-green-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Entrenadores',
              quantity: data.counters.coaches.total,
              icon: 'chalkboard-teacher',
              change: data.counters.coaches.actives,
              changeVar: 'entrenadores activos'
            }"
            bgColor="bg-orange-1100"
          />
        </div>
      </div>

      <div
        class="w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-5"
      >
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Noticias',
              quantity: data.counters.notices.total,
              icon: 'newspaper',
              change: data.counters.notices.actives,
              changeVar: 'noticias activas'
            }"
            bgColor="bg-blue-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Partidos',
              quantity: data.counters.games.total,
              icon: 'volleyball-ball',
              change: data.counters.games.actives,
              changeVar: 'partidos activos'
            }"
            bgColor="bg-yellow-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Categorías',
              quantity: data.counters.categories.total,
              icon: 'chess',
              change: data.counters.categories.actives,
              changeVar: 'Categorías activas'
            }"
            bgColor="bg-orange-1100"
          />
        </div>
        <div style="height: 130px" class="mx-1">
          <stat-card
            :loading="loading"
            :statistic="{
              title: 'Títulos',
              quantity: data.counters.titles.total,
              icon: 'flag-checkered',
              change: data.counters.titles.actives,
              changeVar: 'títulos activos'
            }"
            bgColor="bg-red-1100"
          />
        </div>
      </div>
      <div class="block xl:flex justify-between items-start">
        <div class="w-full xl:w-1/2 px-1">
          <basic-table
            :headers="headers"
            :canCreate="false"
            :canEdit="false"
            :canDelete="false"
            :canExport="false"
            :canChangeStatus="false"
            url="torneos"
            :data="tournaments"
            :hasDetail="false"
            :tableName="'TORNEOS'"
            :seeAll="userRole === 'admin'"
            :loadData="false"
            noResultsMessage="No hay torneos en curso actualmente"
          />
        </div>

        <div class="w-11/12 xl:w-1/2 px-1 mx-auto mt-3 xl:mt-0">
          <scoreboard
            :data="games"
            :route="'/game/grouped_by_date?games=previous'"
            :seeAll="userRole === 'admin'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import StatCard from "@/components/Statistics/Card.vue"
import Scoreboard from "@/components/Games/Scoreboard.vue"
export default {
  components: {
    StatCard,
    Scoreboard
  },
  data() {
    return {
      loading: true,
      submitLoader: false,
      headers: ["", "Nombre", "Categorías", "Equipos", "Partidos", "Fechas"],
      data: {
        counters: {
          tournaments: {},
          academies: {},
          players: {},
          coaches: {},
          notices: {},
          games: {},
          categories: {},
          titles: {}
        },
        tournaments: [],
        today_games: []
      }
    }
  },
  computed: {
    userRole() {
      return this.$store.getters["auth/getUserRole"]
    },
    user() {
      return this.$store.getters["auth/getUser"]
    },
    tournaments() {
      return this.data.tournaments.map((el) => ({
        id: el.id,
        img_logo: el.url_logo,
        name: el.name,
        categories: el.categories,
        teams: el.teams,
        games: el.games.played + " / " + el.games.total,
        dates: el.rounds.played + " / " + el.rounds.total
      }))
    },
    games() {
      return this.data.today_games
    }
  },
  methods: {
    getCounters() {
      this.$store
        .dispatch("global/getItems", {
          route: "/user/admin/counters",
          noPaginate: true
        })
        .then((response) => {
          this.data = response.data
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getCounters()
  }
}
</script>
