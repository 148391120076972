var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"mx-auto text-center"},[_c('t-loader',{attrs:{"extraClass":"border-primary-900 w-8 h-8"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-4"},[_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Torneos',
            quantity: _vm.data.counters.tournaments.total,
            icon: 'trophy',
            change: _vm.data.counters.tournaments.actives,
            changeVar: 'torneos en curso'
          },"bgColor":"bg-yellow-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Academias',
            quantity: _vm.data.counters.academies.total,
            icon: 'shield-alt',
            change: _vm.data.counters.academies.actives,
            changeVar: 'equipos activos'
          },"bgColor":"bg-red-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Jugadores',
            quantity: _vm.data.counters.players.total,
            icon: 'users',
            change: _vm.data.counters.players.actives,
            changeVar: 'jugadores activos'
          },"bgColor":"bg-green-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Entrenadores',
            quantity: _vm.data.counters.coaches.total,
            icon: 'chalkboard-teacher',
            change: _vm.data.counters.coaches.actives,
            changeVar: 'entrenadores activos'
          },"bgColor":"bg-orange-1100"}})],1)]),_c('div',{staticClass:"w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-2 gap-y-4 mb-5"},[_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Noticias',
            quantity: _vm.data.counters.notices.total,
            icon: 'newspaper',
            change: _vm.data.counters.notices.actives,
            changeVar: 'noticias activas'
          },"bgColor":"bg-blue-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Partidos',
            quantity: _vm.data.counters.games.total,
            icon: 'volleyball-ball',
            change: _vm.data.counters.games.actives,
            changeVar: 'partidos activos'
          },"bgColor":"bg-yellow-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Categorías',
            quantity: _vm.data.counters.categories.total,
            icon: 'chess',
            change: _vm.data.counters.categories.actives,
            changeVar: 'Categorías activas'
          },"bgColor":"bg-orange-1100"}})],1),_c('div',{staticClass:"mx-1",staticStyle:{"height":"130px"}},[_c('stat-card',{attrs:{"loading":_vm.loading,"statistic":{
            title: 'Títulos',
            quantity: _vm.data.counters.titles.total,
            icon: 'flag-checkered',
            change: _vm.data.counters.titles.actives,
            changeVar: 'títulos activos'
          },"bgColor":"bg-red-1100"}})],1)]),_c('div',{staticClass:"block xl:flex justify-between items-start"},[_c('div',{staticClass:"w-full xl:w-1/2 px-1"},[_c('basic-table',{attrs:{"headers":_vm.headers,"canCreate":false,"canEdit":false,"canDelete":false,"canExport":false,"canChangeStatus":false,"url":"torneos","data":_vm.tournaments,"hasDetail":false,"tableName":'TORNEOS',"seeAll":_vm.userRole === 'admin',"loadData":false,"noResultsMessage":"No hay torneos en curso actualmente"}})],1),_c('div',{staticClass:"w-11/12 xl:w-1/2 px-1 mx-auto mt-3 xl:mt-0"},[_c('scoreboard',{attrs:{"data":_vm.games,"route":'/game/grouped_by_date?games=previous',"seeAll":_vm.userRole === 'admin'}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }